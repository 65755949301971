
.sticky{
    position:relative;
    top:0;
    bottom:0;
    left: 0;
    right:0;
    position: sticky;
}

#boite{
    background-color: white;
    margin-left: 25%;
    

    
}

.fab {
    justify-content: center;
}
@media only screen and (max-width: 500px) {
    
}