

.containerbis{
    display: block;
    width: 99%;
    height:100%;
   object-fit:cover;
    margin-left: auto;
    margin-right: auto;
    }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(192,192,192,0.9);
    overflow: hidden;
    width: 100%;
    height: 0;
    margin-left: auto;
    margin-right: auto;
    transition: .5s ease;
  }
.containerbis:hover .overlay {
  
  height: 70%;
  width:80%;
  margin-top: 70%;
  margin-bottom: 25%;
  }

  .text1 {
        
    white-space: nowrap; 
    color: white;
    font-size: 15px;
    position: absolute;
    overflow: hidden;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }

  .text2 {
    
    white-space: nowrap; 
    color: white;
    font-size: 15px;
    position: absolute;
    overflow: hidden;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .text3 {
    
    white-space: nowrap; 
    color: white;
    font-size: 15px;
    position: absolute;
    overflow: hidden;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .text4 {
    
    white-space: nowrap; 
    color: white;
    font-size: 13px;
    position: absolute;
    overflow: hidden;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  
  @media only screen and (max-width: 500px) {
    
    .carousel{
      margin-left: -30%;
    }
    .carousel-item img{
      max-width: 300% !important;
     
    height: auto !important;
    object-fit: cover !important;
      
      }

  .containerbis{
    display: block;
    width: 20%;
    height:100%;
   object-fit:cover;
    margin-left: auto;
    margin-right: auto;
    }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(192,192,192,0.9);
    overflow: hidden;
    width: 10%;
    height: 0;
    margin-left: auto;
    margin-right: auto;
    transition: .5s ease;
  }
.containerbis:hover .overlay {
  
  height: 70%;
  width:75%;
  margin-left: auto;
    margin-right: auto;
  }
}