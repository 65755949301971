input[type=number]::-webkit-inner-spin-button,

input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
} 



.clock {
  text-align:center;
  position:relative;
  transform:scale(2);
  border-radius:50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8%;
  margin-bottom: 8%;
  border:2px solid #1976D2; 
  width:30px;
  height:30px;
  position:relative;
}

.clock:after {
  position:absolute;
  width:0px;
  height:10px;
  display:block;
  border-left:2px solid #1976D2;
  border-radius: 2px;
  content:'';
  left:12.5px;
  top:2px;
  animation-duration: 5s;
}

.clock:before {
  position:absolute;
  width:0px;
  height:10px;
  display:block;
  border-left:2px solid #1976D2;
  border-radius: 2px;
  content:'';
  left:12.5px;
  top:2px;
  animation-duration: 60s; 
}

.clock:before,.clock:after {
  transform-origin: bottom;
  animation-name: dial;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes dial {
    0%   {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.animationiconcheck{ 
  animation-name: heartFadeInOut; 
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

@keyframes heartFadeInOut {
  0% {transform: scale(1);}
  25% {transform: scale(.97);}
  35% {transform: scale(.9);}
  45% {transform: scale(1.1);}
  55% {transform: scale(.9);}
  65% {transform: scale(1.1);}
  75% {transform: scale(1.03);}
  100% {transform: scale(1);}
}
.marge{
  margin-left: -4%;
  
}

.piggygif{
  position: relative;
  margin-top: -30%;
  margin-bottom: -34%;
  transform: scale(0.5);
}

.containerthermo {

  position:relative;


}

.gg-bolt {
  box-sizing: border-box;
  position: relative;
  color: #F3F321;
  
  display: block;
  transform: scale(var(--ggs,1));
  width: 6px;
  height: 21px;
  transform: scale(3);
}
.gg-bolt::after,
.gg-bolt::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  transform: rotate(30deg)
}
.gg-bolt::before {
  border-bottom: 12px solid;
  left: 0;
  top: 0
}
.gg-bolt::after {
  border-top: 12px solid;
  right: 0;
  bottom: 0
}

.gg-trees {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 16px;
  height: 20px;
  color : #A66829;
  border-left: 4px solid transparent;
  border-right: 3px solid transparent;
  border-top: 10px solid transparent;
  box-shadow: inset 2px 0 0,inset -2px 0 0;
  transform: scale(2.8);
}
.gg-trees::after,
.gg-trees::before {
  content: "";
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 10px;
  height: 16px;
  border: 2px solid #29A629;
  border-radius: 8px ;
  left: -4px;
  top: -10px
}
.gg-trees::after {
  width: 8px;
  height: 12px;
  top: -6px;
  left: 4px
}

.backgroundpic {
  background-image: url(backgroundpic.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.sillygoose {
  width: 60%;
  height: 80%;
}

.centered{
  margin-left: 20%;
  margin-right: 20%;
}

.backgroundpicbath {
  background-image: url(backgroundpicbath.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.left {
  position:absolute;
  z-index: 2;
  margin-top: -8.5px;
  border: 11px solid;
  border-radius: 50%;
  width: 11px;
  height: 11px;
}



.thermo {
  
  margin-left: auto;
  margin-right: auto;
}


.titrepageprincipale {
  font-size: 230%;
  letter-spacing: 3px;
 
}
.soustitrepageprincipale {
  font-size: 180%;
  letter-spacing: 2px;
}


.animatedbox {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  animation-name: slide-right;
  animation-duration: 3s;
  animation: slide-right 1.5s forwards;
}

@keyframes slide-right {
  from {
    margin-left: 0px;
  }
  to { 
    margin-left: 20%;
  }
  
}
.logocentre {
  font-family:"Helvetica";
  font-size: 20px;
  margin-left:auto;
  margin-right:auto;
  text-align: center; 
}

.containerlogobis {
  display: inline-block;
  width:15%;
  
}

  .logohead {
    display: flex;
    width: 95%;
  }

.logoheadersmallscreen {
  display: none;
}

.animatedbottombox {
  
  animation-name: slide-bottom;
  animation-duration: 3s;
  animation: slide-bottom 1.5s forwards ;
  animation-iteration-count: 1;
}

@keyframes slide-bottom {
  from {
    margin-top: -10%;
  }
  to {
    margin-top: 0%;
  }
  
}

.alerte {
  
  margin-left: auto;
  margin-right: auto;
  font-size: 13px;
  color: #D30303;
  margin-top: 3%;
  margin-bottom: -7%;
}
.alerteslider {
  
  margin-left: auto;
  margin-right: auto;
  font-size: 13px;
  color: #D30303;
  margin-top: -6%;
  margin-bottom: 10%;
}
.ventilation {

  height:auto;
  width: 95%; 
  display: flex;
  padding: 0 0 0 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10%;
  margin-top: 3%;
} 
.check_box {
    display:none;
    
}

.check_box + img{

    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.check_box:checked + img{
    transform: scale(0.9);
    position: static;
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.check_box1 {
    display:none;
}

.check_box1 + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.check_box1:checked + img{
    transform: scale(0.9);
    position : static;
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.check_box2 {
    display:none;
}

.check_box2 + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.check_box2:checked + img{
    transform: scale(0.9);
    position : static;
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.shunt {
    display:none;
}

.shunt + img{
    height:auto;
    
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.shunt:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.Alsace {
    display:none;
}

.Alsace + img{
    height:auto;
    width: 23%; 
    
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.Alsace:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}


.VMCGAZ {
    display:none;
}

.VMCGAZ + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.VMCGAZ:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.Cheminee{
    display:none;
}

.Cheminee + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.Cheminee:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.Ventouse {
    display:none;
}

.Ventouse + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.Ventouse:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.JSP {
    display:none;
}

.JSP + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.JSP:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.Carre {
    display:none;
}

.Carre + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.Carre:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.L {
    display:none;
}

.L + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.L:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.U {
    display:none;
}

.U + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.U:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.IND {
    display:none;
}

.IND + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.IND:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.GC {
    display:none;
}

.GC + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.GC:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.PC {
    display:none;
}

.PC + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.PC:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.DGC {
    display:none;
}

.DGC + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.DGC:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.DPC {
    display:none;
}

.DPC + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.DPC:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.PCGC {
    display:none;
}

.PCGC + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.PCGC:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.field {
    margin-top: 16px;
    margin-left: 0px;
    margin-bottom: 40px;
    width: 30%;
  }

.Select {
  display: flex;
    margin-top: 16px;
    margin-left: auto;
    margin-bottom: 40px;
    margin-right: auto;
    justify-content: center;
    width: 50%;
  }


multi-select-chips {
    display: "flex";
    flex-wrap: "wrap";
  }
  multi-select-chip {
    margin: 2px;
  }

.VMC {
    display:none;
}

.VMC + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.VMC:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.Autre {
    display:none;
}

.Autre + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.Autre:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.foyer1 {
    display:none;
}

.foyer1 + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.foyer1:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.foyer2 {
    display:none;
}

.foyer2 + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.foyer2:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}

.aucun {
    display:none;
}

.aucun + img{
    height:auto;
    width: 23%; 
    display: inline-flex;
    padding: 0 0 0 0px;
    border:#2196F3 1px solid;
    border-radius: 20%;
    margin-top: 3%;
}

.aucun:checked + img{
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
}
.containerbis{
    display: block;
    width: 100%;
    height:100%;
   object-fit:cover;
    margin-left: auto;
    margin-right: auto;
    }
.container{ 
    
    display: block;
    max-width: 100%;
    max-height: 100%;
    min-height:  150px; 
    min-width: 120px;
    object-fit:cover;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: auto;
    align-items:center;

    }
.overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(192,192,192,0.9);
        overflow: hidden;
        width: 40%;
        height: 0;
        margin-left: auto;
        margin-right: auto;
        transition: .5s ease;
      }
.containerbis:hover .overlay {
        height: 70%;
        width:80%;
        margin-top: 70%;
        margin-bottom: 25%;
      }
      
.text {
        
        white-space: nowrap; 
        color: white;
        font-size: 13px;
        position: absolute;
        overflow: hidden;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
      .text1 {
        
        white-space: nowrap; 
        color: white;
        font-size: 15px;
        position: absolute;
        overflow: hidden;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }

      .text2 {
        
        white-space: nowrap; 
        color: white;
        font-size: 15px;
        position: absolute;
        overflow: hidden;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
      .text3 {
        
        white-space: nowrap; 
        color: white;
        font-size: 15px;
        position: absolute;
        overflow: hidden;
        top: 80%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
      .text4 {
        
        white-space: nowrap; 
        color: white;
        font-size: 13px;
        position: absolute;
        overflow: hidden;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
      }
      [type="radio"] {
        display: none;
        vertical-align: baseline;
        
      }
     
      label {
        display: inline;
        position: relative;
        padding-left: 20px;
        margin-right: 5px;
        vertical-align: -10px;
        cursor: pointer;
        font-size: 13.5px;
        text-align: center; 
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: -5px;
        height: 20px;
        width: 20px;
        border: 1px solid  #2196F3;
        background-color: #eee;
        border-radius: 50%;
      }
      
      /* On mouse-over, add a grey background color */
      label:hover [type="radio"] ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the radio button is checked, add a blue background */
      label [type="radio"]:checked ~ .checkmark {
        background-color: #2196F3;
      }
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the indicator (dot/circle) when checked */
      label [type="radio"]:checked ~ .checkmark:after {
        display: block;
        
      }
      
      /* Style the indicator (dot/circle) */
      label .checkmark:after {
           top: 7px;
          left: 7px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
      }

      [type="checkbox"] {
        display: none;
      }

      .check {
        display: inline;
        position: relative;
        padding-left: 25px;
        margin-right: 10px;
        cursor: pointer;
        font-size: 13.5px;
        text-align: center; 
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .check [type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmar {
        position: absolute;
        border-radius: 3px;
        margin-left: -10%;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border: 1px solid  #2196F3;
      }
      
      /* On mouse-over, add a grey background color */
      .check:hover [type="checkbox"] ~ .checkmar {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .check [type="checkbox"]:checked ~ .checkmar {
        background-color: #2196F3;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmar:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .check [type="checkbox"]:checked ~ .checkmar:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .check .checkmar:after {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    
      .textrange {
        width: 8%;
        min-width: 40px;
        padding: 3px 6px;
        margin: 8px 0;
        text-align: center; 
        box-sizing: border-box;
        border: 1px solid #2196F3;
        border-radius: 4px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        outline: none;
      }
      
      .textrange:focus {
        border: 1px solid #555;
        border-radius: 4px;
      }

      a {
        text-decoration: none;
        text-decoration-color: #2196F3;
     }
      .background {
        background-color:#F4F9F9;
        
    }

      .formwizard {
        
        background-color: white;
        border: 3px solid #555;
        margin-left: auto;
        margin-right: auto;
        margin-top : 5%;
        margin-bottom : 5%;
        width : 50%;
        padding: 30px 60px;
        border-radius: 20px;
    }
    .question { 
        font-family:"Helvetica";
        text-align: center; 
        font-size: 20px;
        margin-top : 10px;
        margin-bottom : 25px;
      }
      .questionaddresse { 
        font-family:"Helvetica";
        text-align: center; 
        font-size: 20px;
        margin-top : 10px;
        margin-bottom : 10px;
    
      }
    .buttons {
    text-align: center;
    
}



    .questionconstruction {
        display: flex;
        margin-left: auto;
       margin-right: auto;
}

.field {
    
    margin-top: 16px;
    margin-left: 0px;
    margin-bottom: 40px;
    width: 70%;
  }

 b.bold {
    
    color: #2196F3;

  }

  multi-select-chips {
    display: "flex";
    flex-wrap: "wrap";
  }
  multi-select-chip {
    margin: 2px;
  }
  .adresse{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: "5%";
    margin-right: "5%";
    }
  
    b.malheureusement {
      text-decoration-line: underline;
      text-decoration-color: #2196F3;  
    }

 .titel_color {
  text-align: center;  
  color: #2196F3;

 }
 @media only screen and (min-width: 900px) {
  .petittexte {
    display: none;
  }
 }
 @media only screen and (max-width: 900px) {
  .containerlogo {
    display: block;
    margin: auto;
  }
 .logoheadersmallscreen {
  display: block;
  width:70%;
  height: auto;
  margin: auto;

  
}

.grandtexte {
  display: none;
}

.logohead {
  display: none;
}
.containerlogobis {
  display: none;
  
}
 }
  @media only screen and (max-width: 500px) {
    
    .question label {
      
      display: block;
      flex-direction: column;
      margin-top: 7%;
      justify-content: 'center'; 
      cursor: pointer;
      font-size: 13.5px;
      text-align: center; 
      
     
    }

    .question label > img{
        display: block;
        flex-direction: column;
        margin-top: 7%;
        justify-content: 'center'; 
        font-size: 13.5px;
        text-align: center; 
        width: 80%;
        border:#2196F3 1px solid;
        border-radius: 20%;
        
    }
    .questionconstruction {
        
            display: flex;
            align-items: center;
           justify-content: center;
           width: 105%
    
        
}

.animatedbox {
  width: 75%;
  margin: auto;
  animation-name: slide-right;
  animation-duration: 3s;
  animation: slide-right 1.5s forwards;
}
@keyframes slide-right {
  from {
    margin-left: 0px;
  }
  to { 
    margin-left: 12.5%;
  }
}
.Select {
    margin-top: 16px;
    display: block;
    margin-bottom: 12%;
    width: 70%;
    
  }


  .containerbis{
    display: block;
    width: 99%;
    height:100%;
   object-fit:cover;
    margin-left: auto;
    margin-right: auto;
    }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(192,192,192,0.9);
    overflow: hidden;
    width: 100%;
    height: 0;
    margin-left: auto;
    margin-right: auto;
    transition: .5s ease;
  }
.containerbis:hover .overlay {
  
  height: 70%;
  width:80%;
  margin-top: 70%;
  margin-bottom: 25%;
  }
  .left {
    position:absolute;
    z-index: 2;
    margin-top: -5.5px;
    border: 8px solid;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
  .adresse{
    display: flex;
    width: 100%;
    margin-left: "5%";
    margin-right: "5%";
    }
    .alerteinfo{
      display: flex;
      flex-direction: column;
      width: 150%;
      font-family:"Helvetica";
      font-size: 20px;
      margin-left: -25%;
      margin-right: 5%;
      }

      .logocentre {
      font-family:"Helvetica";
        margin-left:auto;
        margin-right:auto;
        text-align: center; 
      }

      
      .ventilation {

        height:auto;
        width: 95%; 
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        border-radius: 10%;
        margin-top: 3%;
      } 

}
@media only screen and (max-width: 450px) {
  #disparait {
    display: none;
}
.titrepageprincipale {
  font-size: 160%;
  letter-spacing: 2px;
}
.soustitrepageprincipale {
  font-size: 120%;
  letter-spacing: 1px;
}
}