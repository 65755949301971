.suggestions{
    cursor:pointer;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;

}
.suggestions:hover {
    background-color: grey;
}
input[type=textauto] {
    border: 1px solid #2196F3;
    padding: 0.5rem;
    width: 50%;
    margin-bottom : 25px;
  }
  .container1{
    display: block;
    text-align: center;
    border: 1px

}