.box1{
    
    margin-bottom: 30px;
}

.choice{

    flex-direction: row;
}

@media only screen and (min-width: 500px) {
    .box1{
        margin-right: auto;
    margin-left: auto;
        margin-bottom: 30px;
    }
    
    .choice{
        
        flex-direction: row;
    }
}